<template>
  <v-row>
    <v-col class="py-0 px-0" cols="12">
      <l-contrast-theme-provider>
        <l-card-menu>
          <template #content>
            <div class="max-w-full">
              <!--  TODO: w przyszłości przegadać czy robić bardziej dense na mobile  -->
              <h2 v-if="!props.minify" class="headline-2 mb-4" :data-cy="$testids.COMPARE_WIDGET.HEADER">
                  Skorzystaj z kalkulatora rat i sprawdź najlepsze oferty kredytów
              </h2>
              <v-row>
                <v-col cols="12" md="4">
                  <l-input-price
                      label="Cena nieruchomości"
                      :model-value="store.hypothecValue.value"
                      @blur="setHypothecValue"
                      @change="onInputChange"
                      :append-inner-icon="null"
                      :prepend-inner-icon="null"
                      :data-cy="$testids.COMPARE_WIDGET.HYPOTEC_INPUT"
                      :text-center="false"
                      :delay-masked="true"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <l-input-price
                      :label="`Wkład własny • ${store.savingsPercent.value}%`"
                      :model-value="store.savings.value"
                      @blur="setSavings"
                      @change="onInputChange"
                      :append-inner-icon="null"
                      :prepend-inner-icon="null"
                      :data-cy="$testids.COMPARE_WIDGET.SAVINGS_INPUT"
                      :text-center="false"
                      :delay-masked="true"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="4" class="pb-0 md:pb-3">
                  <l-select
                      label="Okres kredytu"
                      :items="durations"
                      :model-value="store.loanPeriod.value"
                      @update:model-value="setLoanPeriod"/>
                </v-col>
              </v-row>
            </div>
          </template>
          <template #action>
            <v-row class="items-end" no-gutters>
              <SimulationOfferDisplayNumber
                  class="!pb-2 md:!pb-6"
                  label="Kwota kredytu"
                  :amount="amount"/>
              <v-col v-if="!props.minify" cols="12">
                <l-btn
                    block
                    size="x-large"
                    type="primary"
                    @click="showOffers"
                    :data-cy="$testids.COMPARE_WIDGET.SHOW_OFFERS_BTN">
                  Przelicz oferty
                </l-btn>
              </v-col>
            </v-row>
          </template>
        </l-card-menu>
      </l-contrast-theme-provider>
    </v-col>
  </v-row>
  <v-row id="alert-section" v-if="store.savingsPercent.value < 20">
    <v-col>
      <l-alert class="mt-5" :data-cy="$testids.COMPARE_WIDGET.ALERT">
        <p class="headline-4">Niski wkład własny (mniej niż 20%)</p>
        <p>Koszta finansowania nieruchomości są wyższe z powodu wkładu własnego mniejszego niż 20% ceny twojej
          nieruchomości</p>
        <template #actions>
          <l-btn type="default" @click="store.setMinSavingsWithoutAdditionalCosts()" size="small">Zwiększ do 20%</l-btn>
        </template>
      </l-alert>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import LCardMenu from "~/components/LComponents/LCard/LCardMenu.vue";
import {useMortgageSimpleSimulationStore} from "~/models/simulation/simpleSimulation/SimpleSimulationInput";
import useDictionaries from "~/models/useDictionaries";
import {toCurrency} from "~/filters/Filters";
import LAlert from "~/components/commons/LAlert.vue";
import LContrastThemeProvider from "~/components/LContrastThemeProvider.vue";
import useScrollTo from "~/service/useScrollTo";
import {wait} from "~/service/Utils";

const MAIN_CONTENT_SELECTOR = '#main-content' as const;

const store = useMortgageSimpleSimulationStore();

const {durations,} = useDictionaries();
const {scrollTo,} = useScrollTo();

const props = withDefaults(defineProps<{
  minify?: boolean,
  shouldRedirect?: boolean
}>(), {
  minify: false,
});

const emit = defineEmits(['fetch','redirect']);

const setHypothecValue = (hypothecValue: number) => {
  store.setHypothecValue(hypothecValue);
};

const onInputChange = (event: Event) => {
  const target: HTMLElement = event.target as HTMLElement;
  if (target) {
    target.blur();
  }
};

const setSavings = (savings: number) => {
  store.setSavings(savings);
};

const setLoanPeriod = (loanPeriod: number) => {
  store.setLoanPeriod(loanPeriod);
};

const amount = computed(() => toCurrency(store.loanAmount.value));

const showOffers = async() => {
  if (props.shouldRedirect) {
    emit('redirect')
  } else {
    emit('fetch');
    await wait(500);
    scrollTo(MAIN_CONTENT_SELECTOR);
  }
};
</script>

